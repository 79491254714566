<div>
  <div class="pl-3 pr-3 w-100">
    <header class="row-detail-header"><b>{{ "APPOINTMENTSEARCH_LABEL_CUSTOMERINFORMATION" | Translate : 'Customer Information:'}}</b></header>
    <div class="d-flex w-100" >
      <div class="mw-50 row-detail-content">
        <table class="table table-borderless">
          <tr>
            <td><b>{{ "APPOINTMENTSEARCH_APPOINTMENT_ID" | Translate : 'Appointment ID:' }}</b> {{ appointment.appointmentId }}</td>
          </tr>
          <tr>
            <td><b>{{ "APPOINTMENTSEARCH_LABEL_NAME" | Translate : 'Name:' }}</b> {{ appointment.customerName }}</td>
          </tr>
          <tr>
            <td><b>{{ "APPOINTMENTSEARCH_LABEL_EMAIL" | Translate : 'Email:' }}</b> {{ appointment.customerEmail }}</td>
          </tr>
          <tr>
            <td><b>{{ "APPOINTMENTSEARCH_LABEL_PHONE" | Translate : 'Phone:' }}</b> {{ appointment.customerPhone }}</td>
          </tr>
          <tr *ngIf='appointment?.customerNotes?.length > 0'>
            <td><b>{{ "APPOINTMENTSEARCH_LABEL_CUSTOMER_NOTES" | Translate : 'Customer Notes:' }}</b> {{ appointment.customerNotes }}</td>
          </tr>
        </table>
      </div>
      <div class="w-50 notes-column">
        <label for="notes">
          <b>{{ "APPOINTMENTSEARCH_LABEL_NOTES" | Translate : 'Notes:' }}</b>
        </label>
        <textarea pInputTextarea id="notes" value="{{appointment.notes}}"
                  (change)="onNoteFieldValueChange($event)" class="notes-text-area"></textarea>
      </div>
    </div>
  </div>
  <div class="pl-3 pr-3 w-100 d-flex flex-row justify-content-between">
    <div class="float-left d-flex flex-row gap-2 justify-content-between">
       <p-button
        pTooltip='{{ "APPOINTMENTSEARCH_LABEL_VIEWCRM" | Translate : "View CRM Record" }}'
        (onClick)="onShowCRMData()">{{ "APPOINTMENTSEARCH_LABEL_VIEWCRM" | Translate : "View CRM Record" }}</p-button>
       <p-button    (onClick)="onShowSessionHistory()"
        pTooltip='{{ "APPOINTMENTSEARCH_LABEL_VIEWSESSIONHISTORY" | Translate : "View Session History" }}'> {{
        "APPOINTMENTSEARCH_LABEL_VIEWSESSIONHISTORY" | Translate : "View Session History" }}</p-button>
    </div>
    <div class="float-right d-flex flex-row gap-2">
      <p-button id="transfer-appt-btn" *ngIf="canTransferOrChangeDisposition()" size="large"
                tooltipPosition="top"
                [pTooltip]='"APPOINTMENTSEARCH_LABEL_TRANSFERAPPOINTMENT" | Translate : "Transfer Appointment"'
                (onClick)="onTransferAppointment()">
        <fa-icon [icon]="faRightLeft"/>
      </p-button>
      <p-button id="close-appt-btn" size="large" tooltipPosition="top" *ngIf="canTransferOrChangeDisposition()"
        [pTooltip]='"APPOINTMENTSEARCH_LABEL_CLOSEAPPOINTMENT" | Translate : "Close Appointment"'
        (onClick)="onCloseAppointment()">
          <fa-icon [icon]="faCalendarCheck" />
      </p-button>
      <p-button id="cancel-appt-btn" size="large" severity="danger" *ngIf="canTransferOrChangeDisposition()" (onClick)="onCancelAppointment()" tooltipPosition="top"
        [pTooltip]='"APPOINTMENT_LABEL_CANCELAPPOINTMENT" | Translate : "Cancel Appointment"'>
          <fa-icon [icon]="faCalendarXmark" />
      </p-button>
      <p-button id="noshow-appt-btn" size="large" *ngIf="canTransferOrChangeDisposition()" (onClick)="onNoShowAppointment()"  tooltipPosition="left"
        [pTooltip]='"APPOINTMENT_LABEL_NOSHOWAPPOINTMENT" | Translate : "Mark Appointment No Show"'>
          <fa-icon [icon]="faUserTimes" size="sm" />
      </p-button>
    </div>
  </div>
</div>
