<td>
  <!--workaround for p-badge directive bug: https://github.com/primefaces/primeng/issues/12736-->
  <div id="status-icon">
    <div [pTooltip]="getStatusText(channel)"
         [title]="getStatusText(channel)"
         [class]="'p-overlay-badge ' + CallStatuses.getStatusFontIconForChannel(channel).css">
      <fa-icon [fixedWidth]="true"
               [icon]="CallStatuses.getStatusFontIconForChannel(channel).icon"></fa-icon>
      <p-badge *ngIf="showBadge"
               [value]="lastMessageFailed ? '!' : unreadMessages"
               [severity]="lastMessageFailed ? 'danger' : 'info'"/>
    </div>
  </div>
</td>
<td id="message-timestamps">
  <p-skeleton *ngIf="!loaded"
              width="3rem"
              height="1.5rem"></p-skeleton>
  <p-badge *ngIf="loaded" [value]="!ended ? timeSinceLastMessage : '0'" severity="info"></p-badge>
</td>
<td colspan="3">
  <app-call-list-rename-chat
    (renameChat)="renameChat.emit($event)"
    [readOnly]="!loaded"
    [chatName]="chatName">
  </app-call-list-rename-chat>
</td>
<td>
  <fa-icon *ngIf="loading"
           [fixedWidth]="true"
           [icon]="faSync"
           animation="spin"></fa-icon>
  <p-skeleton *ngIf="!loading && !loaded"
              shape="circle"
              size="2rem"></p-skeleton>
  <fa-icon *ngIf="loaded" class="info" size="lg" [icon]="faInfoCircle" pTooltip="{{currentPage}}"></fa-icon>
</td>
