import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {
  Appointment,
  AppointmentType,
  CreateAppointmentRequest,
  CreateAvailabilitySlotRequest, EditAppointmentRequest,
  SlotTypes,
  StoreLocation
} from '../crm-service/appointment';
import {environment} from '../../../environments/environment';
import {Agent} from '../../classes/agent';
import {catchError, map} from 'rxjs/operators';
import {StatusEnum} from '../../enums/appointment-status';

export interface Timeslot {
  start: string;
  end: string;
  label?: string;
}

export interface AppointmentTypesResponse {
  success: boolean;
  result: {
    appointmentTypes: AppointmentType[]
  };
}

export interface TimeslotsResponse {
  success: boolean;
  result: {
    timeslots: Timeslot[]
  };
}

export interface LocationResponse {
  success: boolean;
  result: {
    locations: StoreLocation[]
  };
}

export interface AvailableAgent {
  username: string;
  firstname: string;
  lastname: string;
}

export interface AvailableAgentsResponse {
  success: boolean;
  result: {
    agents: AvailableAgent[]
  };
}

export interface AppointmentSearchResponseResult {
  appointments: Appointment[];
  totalAppointments: number;
}

export interface AppointmentSearchResponse {
  success: boolean;
  result: AppointmentSearchResponseResult;
}


export interface IAppointmentBackendService {
  filterAppointmentData(authToken: string, appointmentTypeId: number, callType: string,
                        status: number, agents: string[], from: string, to: string, pagesize: number, pagenumber: number): Observable<AppointmentSearchResponse>;

  createAvailabilityBlock(agent: Agent, request: CreateAvailabilitySlotRequest): Observable<boolean>;

  updateAvailabilityBlock(agent: Agent, appointmentGuid: string, request: CreateAvailabilitySlotRequest): Observable<boolean>;

  deleteAvailabilityBlock(agent: Agent, appointmentGuid: string): Observable<boolean>;

  createAppointment(agent: Agent, appointment: CreateAppointmentRequest): Observable<boolean>;

  updateAppointment(appointmentGuid: string, agent: Agent, appointment: Partial<EditAppointmentRequest>): Observable<boolean>;

  updateAppointmentStatus(appointmentGuid: string, agent: Agent, status: StatusEnum): Observable<boolean>;

  cancelAppointment(agent: Agent, appointmentGuid: string): any;

  loadAppointmentTypes(agent: Agent): Observable<AppointmentType[]>;

  getAvailableAgents(agent: Agent, startTime: string, endTime: string): Observable<AvailableAgent[]>;

  getAvailabilitySlots(agent: Agent, appointmentTypeId: number, appointmentDate: string, clientOffset: number, locationId: number): Observable<Timeslot[]>;

  getLocations(agent: Agent): Observable<StoreLocation[]>;

  reassignAppointmentAgent(appointmentGuid: string, agent: Agent, newAgent: string): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class AppointmentBackendService implements IAppointmentBackendService {


  constructor(
    private http: HttpClient
  ) {
  }

  filterAppointmentData(authToken: string, appointmentTypeId: number, callType: string, status: number, agents: string[],
                        from: string, to: string, pagesize: number, pagenumber: number): Observable<AppointmentSearchResponse> {
    const url = `${environment.restApiRoot}/scheduler/appointment/search`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      appointmentTypeId: appointmentTypeId,
      callType: callType,
      status: status,
      agents: agents,
      start: from,
      end: to,
      pagesize: pagesize,
      pagenumber: pagenumber
    };

    return this.http.post<AppointmentSearchResponse>(url, body, httpOptions).pipe(
      map(response => {
        if (response.success) {
          return response;
        } else {
          throw new Error('Failed to load appointments');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => new Error('Error loading appointments'));
      })
    );
  }

  getAvailableAgents(agent: Agent, startTime: string, endTime: string): Observable<AvailableAgent[]> {
    const url = `${environment.restApiRoot}/scheduler/agents?startTime=${startTime}&endTime=${endTime}&sitename=${agent.sitename.toLowerCase()}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent.authToken
      }),
    };

    return this.http.get<AvailableAgentsResponse>(url, httpOptions).pipe(
      map(response => {
        if (response.success) {
          return response.result?.agents;
        } else {
          throw new Error('Failed to load available agents');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => new Error('Error loading available agents'));
      })
    );

  }

  createAppointment(agent: Agent, appointment: CreateAppointmentRequest): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/appointment`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken,
      })
    };
    const body = {
      userGuid: appointment.userGuid,
      siteSection: appointment.siteSection,
      start: appointment.start,
      end: appointment.end,
      appointmentTypeId: appointment.appointmentTypeId,
      customerInfo: appointment.customerFormData,
      email: appointment.customerEmail,
      phone: appointment.customerPhone,
      culture: appointment.culture,
      locationId: appointment.locationId,
      guests: appointment.guests
    };
    return this.http.post<boolean>(
      url,
      body,
      httpOptions
    );
  }


  updateAppointment(appointmentGuid: string, agent: Agent, appointment: Partial<EditAppointmentRequest>): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/appointment/${appointmentGuid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };

    return this.http.patch<boolean>(
      url,
      appointment,
      httpOptions
    );
  }

  updateAppointmentStatus(appointmentGuid: string, agent: Agent, status: StatusEnum): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/appointment/${appointmentGuid}/status`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };

    return this.http.patch<boolean>(
      url,
      {
        modifiedBy: agent.username,
        status: status,
      },
      httpOptions
    );
  }

  reassignAppointmentAgent(appointmentGuid: string, agent: Agent, newAgent: string): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/appointment/${appointmentGuid}/agent`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };

    return this.http.patch<boolean>(
      url,
      {
        newAgent,
        modifiedBy: agent.username
      },
      httpOptions
    );
  }

  cancelAppointment(agent: Agent, appointmentGuid: string) {
    const url = `${environment.restApiRoot}/scheduler/appointment/${appointmentGuid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };
    return this.http.delete(
      url,
      httpOptions
    );
  }

  loadAppointmentTypes(agent: Agent): Observable<AppointmentType[]> {
    const url = `${environment.restApiRoot}/scheduler/types?sitename=${agent.sitename.toLowerCase()}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };

    return this.http.get<AppointmentTypesResponse>(url, httpOptions).pipe(
      map(response => {
        if (response.success) {
          return response.result?.appointmentTypes;
        } else {
          throw new Error('Failed to load appointment types');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => new Error('Error loading appointment types'));
      })
    );
  }

  createAvailabilityBlock(agent: Agent, request: CreateAvailabilitySlotRequest): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/slots`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };
    const body = {
      type: SlotTypes.Unavailability,
      start: request.startDateTime,
      end: request.endDateTime,
      description: request.description,
      agent: agent.username
    };
    return this.http.post<boolean>(
      url,
      body,
      httpOptions
    );
  }

  updateAvailabilityBlock(agent: Agent, appointmentGuid: string, request: CreateAvailabilitySlotRequest): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/slots/${appointmentGuid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };
    const body = {
      start: request.startDateTime,
      end: request.endDateTime,
      description: request.description,
      agent: agent.username
    };
    return this.http.patch<boolean>(
      url,
      body,
      httpOptions
    );
  }

  deleteAvailabilityBlock(agent: Agent, appointmentGuid: string): Observable<boolean> {
    const url = `${environment.restApiRoot}/scheduler/slots/${appointmentGuid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      })
    };
    return this.http.delete<boolean>(
      url,
      httpOptions
    );
  }

  getAvailabilitySlots(agent: Agent, appointmentTypeId: number, appointmentDate: string, clientOffset: number, locationId: number = -1): Observable<Timeslot[]> {
    const url = `${environment.restApiRoot}/scheduler/slots?appointmentTypeId=${appointmentTypeId}&date=${appointmentDate}&locationId=${locationId}&sitename=${agent.sitename.toLowerCase()}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken
      }),
    };

    return this.http.get<TimeslotsResponse>(url, httpOptions).pipe(
      map(response => {
        if (response.success) {
          return response.result?.timeslots;
        } else {
          throw new Error('Failed to load time slots');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => new Error('Error loading time slots'));
      })
    );
  }

  getLocations(agent: Agent): Observable<StoreLocation[]> {
    const url = `${environment.restApiRoot}/scheduler/locations?sitename=${agent.sitename.toLowerCase()}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': agent?.authToken,
      }),
    };

    return this.http.get<LocationResponse>(url, httpOptions).pipe(
      map(response => {
        if (response.success) {
          return response.result?.locations;
        } else {
          throw new Error('Failed to load locations');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => new Error('Error loading time slots'));
      })
    );
  }
}
