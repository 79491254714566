
<p-iconField iconPosition="right">
  <p-inputIcon *ngIf="!readOnly">
    <fa-icon [icon]="faPencilAlt"></fa-icon>
  </p-inputIcon>
  <input pInputText class="wrapper-input w-100" placeholder='{{ "CALLLIST_PLACEHOLDER_RENAMECHAT" | Translate : "Rename chat"}}'
         (keyup.enter)="onRenameChat($event)" (blur)="onRenameChat($event)"
         [readOnly]="readOnly"
         [value]="chatName">
</p-iconField>
