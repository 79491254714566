import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {timer} from 'rxjs';
import {faExclamationCircle, faHourglass, faInfoCircle, faPencilAlt, faSpinner, faSync} from '@fortawesome/free-solid-svg-icons';
import {TimeUtil} from '../../../../utils/time-util';
import {Channels} from '../../../../services/visitor-service/Channel';
import {CallStatuses} from "../../../../utils/call-statuses";

@Component({
  selector: '[app-veechat-call-list-conversation-row]',
  templateUrl: './veechat-call-list-conversation-row.component.html',
  styleUrls: ['./veechat-call-list-conversation-row.component.scss']
})
export class VeechatCallListConversationRowComponent implements OnInit {
  public faInfoCircle = faInfoCircle;

  @Input() ended = false;
  @Input() loaded = false;
  @Input() loading = false;

  @Input() chatName = '';
  @Output() renameChat = new EventEmitter<String>();

  @Input() unreadMessages: number;
  @Input() currentPage: string;

  @Input() channel: Channels;

  private _lastContactTime = new Date();
  @Input() set lastContactTime(lastContactTime: Date) {
    this._lastContactTime = lastContactTime;
    this.calcTimeSinceLastMessage();
  }

  timeSinceLastMessage = '0';

  private lastMessageInterval;

  @Input()
  public lastMessageFailed: boolean = false;
  faExclamationCircle = faExclamationCircle;

  get showBadge() {
    return this.unreadMessages > 0;
  }

  constructor() {
  }


  ngOnInit(): void {
    this.lastMessageInterval = timer(1000, 1000).subscribe(() => this.calcTimeSinceLastMessage());
  }

  ngOnDestroy(): void {
    this.lastMessageInterval.unsubscribe();
  }

  private calcTimeSinceLastMessage() {
    this.timeSinceLastMessage = TimeUtil.PrettyRelativePastTime(this._lastContactTime);
  }

  public getStatusText(channel: Channels) {
    switch (channel) {
      case Channels.WhatsApp:
        return 'WhatsApp';
      case Channels.Messenger:
        return 'Messenger';
      case Channels.Sms:
        return 'SMS';
      default:
        return 'Unknown';
    }
  }

  protected readonly CallStatuses = CallStatuses;
  protected readonly faSync = faSync;
}
