<form [formGroup]="availabilityFormGroup" class="form-container mt-4">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label
        for="start-date">{{ "APPOINTMENTSEARCH_LABEL_UNAVAILABLESTART_DATE" | Translate : 'Start Date' }}</label>
      <input formControlName="startDate" id="start-date" pInputText type="date"
             class="form-control">
    </div>
    <div class="form-group col-md-6">
      <label for="start-time">{{ "APPOINTMENTSEARCH_LABEL_UNAVAILABLESTART_TIME" | Translate : 'Start Time' }}
        ({{ timezoneAbrv }})</label>
      <input  formControlName="startTime" pInputText type="time" class="form-control"
              id="start-time">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="end-date">{{ "APPOINTMENTSEARCH_LABEL_UNAVAILABLEEND_DATE" | Translate : 'End Date' }} </label>
      <input formControlName="endDate"  id="end-date" pInputText type="date"
             class="form-control">
    </div>
    <div class="form-group col-md-6">
      <label for="end-time">{{ "APPOINTMENTSEARCH_LABEL_UNAVAILABLEEND_TIME" | Translate : 'End Time' }}
        ({{ timezoneAbrv }})</label>
      <input formControlName="endTime"  pInputText type="time" id="end-time"
             class="form-control">
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-12">
      <div class="description-labels">
        <label for="description">{{ "APPOINTMENTSEARCH_LABEL_DESCRIPTION" | Translate : 'Description' }}</label>
        <label *ngIf="remainingCharacters < 0" class="text-danger">{{ "APPOINTMENTSEARCH_DESCRIPTION_CHAR_LIMIT_WARNING" |
          Translate : 'You have exceeded the character limit'}}
        </label>
        <label class="char-limit">{{ remainingCharacters }}/{{ maxCharacters }}</label>
      </div>
      <textarea id="description" formControlName="description" class="form-control" pInputTextarea
                rows="3"></textarea>
    </div>

  </div>

  <div class="d-flex flex-row align-items-center justify-content-end gap-1 mt-2">

    <p-button *ngIf="exists(appointment) && canEditAvailability"
              (onClick)="deleteAvailabilityBlock()"
              pTooltip='{{ "APPOINTMENTSEARCH_LABEL_DELETE" | Translate : "Delete" }}'
              severity="danger">
      <fa-icon [icon]="faTrash"></fa-icon>
    </p-button>

    <p-button (onClick)="saveAvailabilityBlock()" type="submit"
              [disabled]="!availabilityFormGroup.valid || !availabilityFormGroup.valid || remainingCharacters < 0"
              pTooltip='{{ "APPOINTMENTSEARCH_LABEL_SAVE_UNAVAILABLE_BLOCK" | Translate : "Save Unavailable Block" }}'
              severity="success">
      <fa-icon [icon]="faSave"></fa-icon>
    </p-button>
  </div>


</form>
