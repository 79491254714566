import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {VisitorService} from '../../../services/visitor-service/visitor.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {SiteVisitor} from '../../../services/visitor-service/SiteVisitor';
import {AlertService, AlertType} from '../../../services/alert-service/alert.service';
import {LoadingService} from '../../../services/loading.service';
import {map, take, tap} from 'rxjs/operators';
import {EngagementService} from '../../../services/engagement.service';
import {LoggingService} from '../../../services/logging.service';
import {Engagement, EngagementState} from '../../../services/engagement';
import {InvitationRequest} from '../../../classes/transfer/invitationRequest';
import {InvitationInitialState, InvitationInModalComponent} from '../../../components/invitation-modal/invitation-in-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {NotificationService} from '../../../services/notification.service';
import Guid from '../../../classes/Guid';
import {AuthService} from '../../../services/auth-service/auth.service';
import {Agent} from '../../../classes/agent';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import {Features, FeatureService} from '../../../services/feature-service/feature.service';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {VisitorUtils} from '../../../utils/visitor-utils';
import {AsyncConversation} from '../../../services/async-conversation';
import {WorkStatus} from '../../../classes/work-status';
import {MissingVisitorModalContentComponent} from '../../veestudio/engagement/engagement-missing-visitor-modal-content/engagement-missing-visitor-modal-content.component';
import {OnlineState} from "../../../enums/online-state.enum";
import {ModalService} from '../../../services/modal.service';
import {MenuItem} from "primeng/api";
import {Menu} from "primeng/menu";

export enum DefaultGroup {
  'NoSuperVisor' = "N/A"
}

export enum VeechatSelection {
  visitor = 'visitor',
  engagement = 'engagement',
  conversation = 'conversation',
  transfer = 'transfer',
  invite = 'invite',
  none = 'none',
}

export interface VeechatVisitorSelection {
  type: VeechatSelection.visitor;
  visitor: SiteVisitor;
}

export interface VeechatEngagementSelection {
  type: VeechatSelection.engagement;
  id: string;
}

export interface VeechatEmptySelection {
  type: VeechatSelection.none;
}

export interface VeechatTransferSelection {
  type: VeechatSelection.transfer;
  transferRequest: InvitationRequest;
}

export interface VeechatInviteSelection {
  type: VeechatSelection.invite;
  joinRequest: InvitationRequest;
}

export interface VeeChatConversationSelection {
  type: VeechatSelection.conversation;
  id: string;
}

export type VeechatSelectionType =
  VeechatInviteSelection
  | VeechatTransferSelection
  | VeechatEngagementSelection
  | VeechatVisitorSelection
  | VeechatEmptySelection
  | VeeChatConversationSelection;

@Component({
  selector: 'app-veechat-call-list',
  templateUrl: './veechat-call-list.component.html',
  styleUrls: ['./veechat-call-list.component.scss'],
  providers: [TranslatePipe]
})
export class VeechatCallListComponent implements OnInit, OnDestroy {
  protected faCaretDown = faCaretDown;
  protected faCaretUp = faCaretUp;
  protected readonly FilterChatType = WorkStatus;
  protected VeechatSelection = VeechatSelection; // to export to the view

  private static readonly MISSING_VEECHAT_TIMEOUT = 90; // 90 seconds before the chat is closed due to a missing visitor
  public static readonly NO_SELECTION: VeechatEmptySelection = {type: VeechatSelection.none};

  private inviteModalRef: DynamicDialogRef;
  private closeModalRef: DynamicDialogRef;

  public currentSelection: BehaviorSubject<VeechatSelectionType> = new BehaviorSubject<VeechatSelectionType>(VeechatCallListComponent.NO_SELECTION);
  @Output() selectionChange = new EventEmitter<VeechatSelectionType>();

  public invites: Observable<InvitationRequest[]>;
  public engagements: Observable<Engagement[]>;
  private sortedVisitors: Observable<SiteVisitor[]>;

  private subscriptions: Array<Subscription> = [];

  public visitors: SiteVisitor[] = [];
  public transfers: Observable<InvitationRequest[]>;

  public selectedGroupList: string[] = [];
  public currentAgent: Agent;
  public groups: Set<string> = new Set();

  public groupIsCollapse: boolean[] = [];

  protected displayFilter: BehaviorSubject<WorkStatus> = new BehaviorSubject(WorkStatus.Multichat | WorkStatus.Async);
  @Output() workingStatusChange: EventEmitter<WorkStatus> = new EventEmitter<WorkStatus>();

  public hasAsync: boolean = false;

  public menuItems: MenuItem[] = [
    {
      label: this.translate.transform('CALLLIST_FILTER_ALL', 'All'),
      command: () => {
        this.displayFilter.next(WorkStatus.Multichat + WorkStatus.Async);
      }
    },

    {
      label: this.translate.transform('CALLLIST_FILTER_MULTICHAT', 'VeeChat'),
      command: () => {
        this.displayFilter.next(WorkStatus.Multichat);
      }
    },
    {
      label: this.translate.transform('CALLLIST_FILTER_ASYNC', 'Async'),
      command: () => {
        this.displayFilter.next(WorkStatus.Async);
      }
    }
  ];

  public activeItem: MenuItem = this.menuItems[0];

  constructor(
    private alertService: AlertService,
    private loadingService: LoadingService,
    protected visitorService: VisitorService,
    private engagementService: EngagementService,
    private logger: LoggingService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private featureService: FeatureService,
    private translate: TranslatePipe,
  ) {
    this.subscriptions.push(
      this.displayFilter.pipe(tap(w => this.workingStatusChange.emit(w))).subscribe());
  }

  ngOnInit()
  {
    this.engagements = this.engagementService.engagements;

    this.currentAgent = this.authService.currentAgent.value;
    if (!this.currentAgent.isSupervisor) {
      this.selectedGroupList = [DefaultGroup.NoSuperVisor];
      this.groupIsCollapse[DefaultGroup.NoSuperVisor] = false;
    } else {
      this.subscriptions.push(combineLatest([this.visitorService.agentStatus, this.authService.currentAgent]).pipe(
        map(([agentStatusMap, currentAgent]) => {
          const agents = Array.from(agentStatusMap.values()).filter(agentStatus => agentStatus.status === OnlineState.MultiChat);
          const currentAgentGroups = currentAgent?.groups?.map(group => group.Name) || [];
          return [...currentAgentGroups, ...agents.map(agent => agent.groups.map(group => group.Name)).flat()];
        })
      ).subscribe((groups) => {
        this.groups = new Set(groups);
        this.selectedGroupList = Array.from(this.groups);
      }));
    }

    this.hasAsync = this.featureService.has(Features.DISPLAY_ASYNC_CHATS);
    if (!this.hasAsync) {
      this.displayFilter.next(WorkStatus.Multichat);
    }

    this.invites = this.visitorService.invites;
    const invSub = this.invites.subscribe(newInvites => {
      if (this.currentSelection.value.type === VeechatSelection.invite) {
        const inviteSelection: VeechatInviteSelection = this.currentSelection.value;
        if (!newInvites.some(jr => jr.roomId === inviteSelection.joinRequest.roomId)) {
          // set the select state to nothing if the transfer has been removed
          this.deselectJoin();
        }
      }
    });
    this.subscriptions.push(invSub);

    this.transfers = this.visitorService.transfers;
    this.transfers.subscribe(newTransfers => {
      if (this.currentSelection.value.type === VeechatSelection.transfer) {
        const transferSelection: VeechatTransferSelection = this.currentSelection.value;
        if (!newTransfers.some(tr => tr.roomId === transferSelection.transferRequest.roomId)) {
          // set the select state to nothing if the transfer has been removed
          this.deselectJoin();
        }
      }
    });

    this.sortedVisitors =
      combineLatest([this.displayFilter, this.visitorService.visitors])
        .pipe(map(([filter, v]) => {
          const f = v.filter(vis => {
            return ((filter & WorkStatus.Async) && vis.isAsync) ||
                   ((filter & WorkStatus.Multichat) && !vis.isAsync);
          });
          f.sort(VisitorUtils.veechatSort);
          return f;
        }));

    const combinedSub = combineLatest([
        this.sortedVisitors,
        this.visitorService.invites,
        this.visitorService.transfers,
        this.engagementService.engagements,
        this.visitorService.conversations])
      .pipe(map(([vis, invites, transfers, _, conversations]) => {
        return vis.filter(v => {
          // remove visitors for engagement I'm already in
          if (this.engagementService.isEngagedByMe(v)) {
            return false;
          }

          // remove async conversations if not showing the filter
          if (!this.hasAsync && v.isAsync) {
            return false;
          }

          // remove async conversations I'm dealing with.
          if (conversations.some(c => c.id.Id === v.sessionGuid)) {
            return false;
          }

          // remove visitors for invites I have
          if (invites.some(inv => inv.visitor.userGuid === v.userGuid)) {
            return false;
          }

          // remove transfers for invites I have
          if (transfers.some(xfr => xfr.visitor.userGuid === v.userGuid)) {
            return false;
          }

          // Otherwise we want this visitor on the screen
          return true;
        });
    })).subscribe(newVisitors => {
        this.visitors = newVisitors;
        this.updateVisitorSelection();
      });
    this.subscriptions.push(combinedSub);

    // This won't ring if the visitors get replaced instead of being added, but it should be good enough.
    let previousActiveVisitorCount = 0;
    this.subscriptions.push(
      this.sortedVisitors
        .subscribe(visitors => {
          const activeVisitorCount = visitors.filter(v => !v.isInactive && !v.isEngaged).length;
          if (activeVisitorCount > previousActiveVisitorCount) {
            this.alertService.playNewChatSound();
            const title = this.translate.transform("CALLLIST_TITLE_REQUESTFORASSISTANCE", 'Request for Assistance');
            const options = { body: 'New Chat', icon: '../../assets/images/veechat-icon.png', tag: 'visitorCall', silent: true };
            this.notificationService.create(title, options);
          }
          previousActiveVisitorCount = activeVisitorCount;
        })
    );

    let previousTransferCount = 0;
    this.subscriptions.push(
      this.visitorService.transfers
        .subscribe(transfers => {
          if (transfers.length > previousTransferCount) {
            this.alertService.playNewChatSound();
            const title = this.translate.transform("ENGAGEMENTTRANSFER_STATETEXT_TRANSFER", 'Transfer');
            const options = { body: 'New Chat', icon: '../../assets/images/veechat-icon.png', tag: 'visitorCall', silent: true };
            this.notificationService.create(title, options);
          }
          previousTransferCount = transfers.length;
        })
    );

    let previousJoinInviteCount = 0;
    this.subscriptions.push(
      this.visitorService.invites
        .subscribe(invites => {
          if (invites.length > previousJoinInviteCount) {
            this.alertService.playNewChatSound();
            const title = this.translate.transform("CALLLIST_LABEL_JOINREQUEST", 'Join Request');
            const options = { body: 'New Chat', icon: '../../assets/images/veechat-icon.png', tag: 'visitorCall', silent: true };
            this.notificationService.create(title, options);
          }
          previousJoinInviteCount = invites.length;
        })
    );


  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public accept(visitor: SiteVisitor): void {
    this.loadingService.isLoading.next(true);

    if (visitor.isAsync) {
      this.loadingService.isLoading.next(false);
      if (visitor.isEngaged) {
        this.alertService.addAlert("Conversation already in progress", AlertType.Info);
      } else {
        this.visitorService.acceptEngagement(visitor).subscribe(
          response => {
            if (response.success) {
              visitor.beingTransferred = false;
              this.onConversationAccepted(response.message);
            } else {
              this.alertService.addAlert(
                this.translate.transform(
                  "CALLLIST_ALERT_ERRORACCEPTREQUEST",
                  `Error accepting conversation request ${response.message}`),
                AlertType.Danger);
            }
          }
        );
      }
    }
    else if (visitor.isEngaged) {
      if (Guid.isValidUUID(visitor.engagementGuid)) {
        // If the visitor is engaged then we want to join the call
        this.visitorService.joinCall(visitor).subscribe(response => {
          this.onAccepted(visitor.engagementGuid);
          this.loadingService.isLoading.next(false);
        }, err => {
          this.loadingService.isLoading.next(false);
        });
      }
    } else {
      // Otherwiwse we want to start a new call
      this.visitorService.acceptEngagement(visitor).subscribe(
        response => {
          if (response.success) {
            this.onAccepted(response.message.engagementId);
          } else {
            this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERRORACCEPTREQUEST", `Error accepting engagement request  ${response.message}`), AlertType.Danger);
          }
        },
        error => {
          this.logger.error('Error accepting call', error);
          this.alertService.addAlert(this.translate.transform('CALLLIST_ALERT_TIMEOUT', `TIMEOUT`), AlertType.Danger);
          this.loadingService.isLoading.next(false);
        },
        () => {
          this.loadingService.isLoading.next(false);
        }
      );
    }
  }

  private onAccepted(engagementId: string) {
    this.logger.debug(`Accepted engagement id: ${engagementId}`);
    const engagement = this.engagementService.getEngagement(engagementId);
    // todo: need to fix this so that it doesn't connect if the hub connection fails
    // cf. the veestudio startup piece.
    engagement.initialise().subscribe(success => {
      if (!success) {
        // If we fail to connect then go to post and display a warning
        // todo: need to make the engagement start in an IDLE state and then transition into engaged.
        // that makes more sense, plus we can then do something different in the UI
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_CONNECTIONERROR", `Unable to connect to engagement. Please check your internet connection.`), AlertType.Danger);
        engagement.endChat();
      } else {
        if (this.featureService.has(Features.TRANSLATOR)) {
          engagement.setTranslationEnabled(true);
        }
        // Enable private chat if we are joining as a supervisor
        if (this.authService.currentAgent.value.isSupervisor &&
            this.featureService.has(Features.PRIVATE_CHAT)) {
          engagement.enablePrivateChat();
        }


        engagement.visitorJoined$.subscribe((newVisitor) => {
          this.visitorService.getVisitorDetails(newVisitor.userGuid, newVisitor.sessionGuid).subscribe(details => {
            newVisitor.visitorDetails$.next(details);
          });

          engagement.updateAllVisitorData();

          if (engagement.roomVisitors.size > 1) {
            engagement.showPage(engagement.currentPage.getValue(), true);
            const message = this.translate.transform('INTIMATE_VISITOR_JOINED', 'Visitor joined.');
            engagement.addInternalMessage(message);
            this.alertService.playVisitorJoinedSound();
          }
        });

        engagement.visitorLeft$.subscribe(() => {
          if (engagement.roomVisitors.size > 0) {
            const message = this.translate.transform('INTIMATE_VISITOR_LEFT', 'Visitor left.');
            engagement.addInternalMessage(message);
          }
        });
      }
    });

    // trigger a notification for a new message
    engagement.newMessage.subscribe(({ privateMessage, message }) => {
      if (message) {
        if (privateMessage) {
          this.alertService.playNewPrivateMessageSound();
        } else {
          this.alertService.playNewMessageSound();
        }

        const title = 'New Message';
        const options = {body: message.message, icon: '../../assets/images/veechat-icon.png', tag: 'visitorMessage', silent: true};
        this.notificationService.create(title, options);
      }
    });

    // Also wire up the missing listener
    // The engagement takes care of only firing this for primary agents
    let count = 0;
    const s = engagement.visitorMissing.subscribe(missing => {
      if (missing) {
        if (++count > VeechatCallListComponent.MISSING_VEECHAT_TIMEOUT) {
          if (this.currentSelection.value.type === VeechatSelection.engagement) {
            const selection: VeechatEngagementSelection = this.currentSelection.value;
            if (selection.id === engagementId) {
              const initialState = {
                end: () => {
                  this.removeVisitorMissingModal();
                  engagement.endChat();
                },
                wait: () => {
                  count = 0;
                  this.removeVisitorMissingModal();
                },
              };
              this.maybeShowVisitorMissingModal(initialState);
            } else {
              this.logger.debug(``);
            }
          }
        }
      } else {
        if (this.currentSelection.value.type === VeechatSelection.engagement) {
          const selection: VeechatEngagementSelection = this.currentSelection.value;
          if (selection.id === engagementId) {
            this.removeVisitorMissingModal();
          }
        }
        count = 0;
      }
    }, () => {
    }, () => {
      if (this.currentSelection.value.type === VeechatSelection.engagement) {
        const selection: VeechatEngagementSelection = this.currentSelection.value;
        if (selection.id === engagementId) {
          this.removeVisitorMissingModal();
        }
      }
    });

    engagement.currentState.subscribe(state => {
      if (EngagementState.Ended === state.type) {
        this.engagementService.endEngagement(engagementId);
        if (this.currentSelection.value.type === VeechatSelection.engagement) {
          const selection: VeechatEngagementSelection = this.currentSelection.value;
          if (selection.id === engagementId) {
            let longestWaitedEngagement = this.selectLongestWaitedEngagement();
            if (longestWaitedEngagement !== undefined) {
              this.selectEngagement(longestWaitedEngagement);
            } else {
              this.selectNothing();
            }
          }
        }
      }
    });
    this.selectEngagement(engagement)
  }

  selectLongestWaitedEngagement() {
    let engagementData: Engagement[];
    this.engagements.pipe(take(1)).
      subscribe(items => {
        items.sort((prev, current) => prev.lastContactTime.value.getTime() - current.lastContactTime.value.getTime());
        engagementData = items;
      });
    return engagementData[0];
  }

  protected selectVisitor(visitor: SiteVisitor) {
    if (!visitor.isInactive) {
      const selection: VeechatVisitorSelection = {type: VeechatSelection.visitor, visitor: visitor};
      this.emitSelection(selection);
    }
  }

 protected selectEngagement(engagement: Engagement) {
    const selection: VeechatEngagementSelection = {type: VeechatSelection.engagement, id: engagement.engagementId.toString()};
    this.emitSelection(selection);
  }

  private selectNothing() {
    this.emitSelection(VeechatCallListComponent.NO_SELECTION);
  }

  private emitSelection(newSelection: VeechatSelectionType) {
    this.currentSelection.next(newSelection);
    this.selectionChange.emit(newSelection);
  }

  private updateVisitorSelection() {
    switch (this.currentSelection.value.type) {
      case VeechatSelection.visitor:
        const id = this.currentSelection.value.visitor.userGuid;
        if (this.visitors.findIndex(v => v.userGuid === id) === -1) {
          this.selectNothing();
          // visitor has been remove from the list
        }
        break;
      default:
        break;
    }
  }

  selectTransferIn(transferRequest: InvitationRequest) {
    const transferSelection: VeechatTransferSelection = {
      type: VeechatSelection.transfer,
      transferRequest: transferRequest,
    };

    this.emitSelection(transferSelection);
  }

  public showTransferAccept(transferRequest: InvitationRequest) {
    this.selectTransferIn(transferRequest);

    const data: InvitationInitialState = {
      acceptCallback: () => this.acceptTransferringIn(transferRequest),
      cancelCallback: () => this.deselectJoin(),
      sendMessage: (message: string) => {
        this.visitorService.sendTransferChatMessage(transferRequest.roomId, transferRequest.sourceAgentUsername, message).subscribe();
      },
      rejectCallback: (rejectReason) => this.rejectTransfer(transferRequest, rejectReason),
      invitationRequest: transferRequest,
      textResources: {
        transferRequestTitle: this.translate.transform("CALLLIST_TITLE_TRANSFERREQUEST", 'Transfer Request'),
        from: this.translate.transform("CALLLIST_LABEL_FROM", 'From:'),
        reason: this.translate.transform("CALLLIST_LABEL_REASON", 'Reason:'),
        transcriptTitle: this.translate.transform("CALLLIST_LABEL_CHATTRANSCRIPT", 'Chat Transcript'),
        rejectTitle: this.translate.transform("CALLLIST_LABEL_REJECTTRANSFER", 'Reject this Transfer'),
        rejectionReasonTitle: this.translate.transform("CALLLIST_LABEL_REJECTIONREASON", 'Rejection reason'),
      },
    };

    this.inviteModalRef = this.modalService.openModal(InvitationInModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform("CALLLIST_TITLE_TRANSFERREQUEST", 'Transfer Request'),
      contentStyle: { width: '500px'}
    });
  }

  public acceptTransferringIn(transferRequest: InvitationRequest): void {
    if (this.inviteModalRef) {
      this.modalService.closeModal(this.inviteModalRef);
      this.inviteModalRef = null;
    }

    this.loadingService.isLoading.next(true);

    if (transferRequest.isConversation) {
      this.visitorService.sendWarmTransferAccept(transferRequest).subscribe(response => {
        if (response.success) {
          this.logger.info(`Accepted transfer for conversation ${transferRequest.roomId}`);
        } else {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERRORACCEPTREQUEST", `Error accepting engagement request  ${response.message}`), AlertType.Danger);
        }
        this.loadingService.isLoading.next(false);
      });
    } else {
      this.visitorService.acceptTransferringIn(transferRequest).subscribe(
        response => {
          if (response.success) {
            this.onAccepted(transferRequest.roomId);
          } else {
            this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERRORACCEPTREQUEST", `Error accepting engagement request  ${response.message}`), AlertType.Danger);
          }
        },
        error => {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
          this.loadingService.isLoading.next(false);
        },
        () => {
          this.loadingService.isLoading.next(false);
        }
      );
    }
  }

  public deselectJoin() {
    if (this.inviteModalRef) {
      this.modalService.closeModal(this.inviteModalRef);
      this.inviteModalRef = null;
    }
    this.selectNothing();
  }

  public rejectTransfer(transferRequest: InvitationRequest, rejectionText: string) {
    if (this.inviteModalRef) {
      this.modalService.closeModal(this.inviteModalRef);
      this.inviteModalRef = null;
    }

    this.loadingService.isLoading.next(true);

    this.visitorService.rejectTransferringIn(transferRequest.roomId, transferRequest.sourceAgentUsername, rejectionText).subscribe(
      response => {
        if (response.success) {
          this.loadingService.isLoading.next(false);
          this.selectNothing();
        } else {
          this.alertService.addAlert(`Error :  ${response.message}`, AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  public selectJoinIn(invite: InvitationRequest) {
    const joinSelection: VeechatInviteSelection = {
      type: VeechatSelection.invite,
      joinRequest: invite,
    };

    this.emitSelection(joinSelection);
  }

  public showJoinAccept(invite: InvitationRequest): void {
    this.selectJoinIn(invite);

    const data: InvitationInitialState = {
      acceptCallback: () => this.acceptJoin(invite),
      cancelCallback: () => this.deselectJoin(),
      sendMessage: (message: string) => {
        this.visitorService.sendTransferChatMessage(invite.roomId, invite.sourceAgentUsername, message).subscribe();
      },
      rejectCallback: (rejectReason) => this.rejectJoin(invite, rejectReason),
      invitationRequest: invite,
      textResources: {
        transferRequestTitle: this.translate.transform("CALLLIST_LABEL_JOINREQUEST", 'Join Request'),
        from: this.translate.transform("CALLLIST_LABEL_FROM", 'From:'),
        reason: this.translate.transform("CALLLIST_LABEL_REASON", 'Reason:'),
        transcriptTitle: this.translate.transform("CALLLIST_LABEL_CHATTRANSCRIPT", 'Chat Transcript'),
        rejectTitle: this.translate.transform("CALLLIST_LABEL_REJECTINVITE", 'Reject this Invite'),
        rejectionReasonTitle: this.translate.transform("CALLLIST_LABEL_REJECTIONREASON", 'Rejection reason'),
      },
    };

    this.inviteModalRef = this.modalService.openModal(InvitationInModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform("CALLLIST_LABEL_JOINREQUEST", 'Join Request'),
      contentStyle: {width: '500px'}
    });
  }

  public acceptJoin(invite: InvitationRequest): void {
    if (this.inviteModalRef) {
      this.modalService.closeModal(this.inviteModalRef);
      this.inviteModalRef = null;
    }

    this.loadingService.isLoading.next(true);

    this.visitorService.acceptJoin(invite).subscribe(
      response => {
        if (response.success) {
          this.onAccepted(invite.roomId);
        } else {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERRORACCEPTREQUEST", `Error accepting engagement request  ${response.message}`), AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  public rejectJoin(invite: InvitationRequest, reason: string): void {
    if (this.inviteModalRef) {
      this.modalService.closeModal(this.inviteModalRef);
      this.inviteModalRef = null;
    }

    this.loadingService.isLoading.next(true);

    this.visitorService.rejectInvitation(invite.roomId, invite.sourceAgentUsername, reason).subscribe(
      response => {
        if (response.success) {
          this.loadingService.isLoading.next(false);
          this.selectNothing();
        } else {
          this.alertService.addAlert(`Error :  ${response.message}`, AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  onRenameChat(engagement: Engagement, newChatName: string) {
    engagement.setChatName(newChatName);
  }


  private onConversationAccepted(conversation: AsyncConversation) {
    this.selectConversation(conversation);
  }

  public selectConversation(conversation: AsyncConversation): void {
    conversation?.loadData().catch(err => this.logger.error(err));

    const selection: VeeChatConversationSelection = {
      type: VeechatSelection.conversation,
      id: conversation.id.Id,
    };

    this.emitSelection(selection);
  }

  private maybeShowVisitorMissingModal(initialState: {wait: () => void, end: () => void}) {
    if (this.modalService.isModalShowing()) {
      this.logger.debug('Already showing modal for ending engagement');
    } else {
      this.logger.debug('Visitor is missing');
      this.closeModalRef = this.modalService.openModal(MissingVisitorModalContentComponent, {
        data: initialState,
        closeOnEscape: false,
        showHeader: true,
        closable: false,
        header: this.translate.transform('VISITORMODAL_HEADER_WAIT', 'Wait for customer?'),
        contentStyle: {width: '500px'}
      });
    }
  }

  private removeVisitorMissingModal() {
    if (this.closeModalRef) {
      this.modalService.closeModal(this.closeModalRef);
      this.closeModalRef = null;
    }
  }

  protected readonly Array = Array;
}
